<template>
    <section class="staffManage">
        <h2 class="title-page">员工管理</h2>
        <div class="content-staff">
            <div class="staff-flex staff-info">
                <div class="staff-flex">
                    <div class="staff-flex">
                        <label class="select-label first-label">岗位</label>
                        <el-select v-model="checkStation" placeholder="请选择" @change="getStaffList">
                            <el-option
                                v-for="item in stationList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="staff-flex">
                        <label class="select-label">服务区域</label>
                        <el-select v-model="checkArea" placeholder="请选择" @change="getStaffList">
                            <el-option
                                v-for="item in areaList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="staff-flex">
                        <label class="select-label">姓名</label>
                        <el-input placeholder="请输入姓名" v-model="name" >
                            <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="btn-div">
                <el-button type="primary" @click="showEdit(1)">设置岗位</el-button>
                <el-button type="primary" @click="showEdit(2)">设置权限门店</el-button>
                <el-button type="primary" @click="showEdit(3)">设置服务区域</el-button>
                <el-button type="primary" @click="syncUser" v-if="account == 'admin'">同步企微通讯录</el-button>
            </div>
            <el-table align="center" :data="tableData" style="width: 100%" @selection-change="selectionChange"
                      :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="姓名" width="150"></el-table-column>
                <el-table-column prop="user_id" label="用户id"  ></el-table-column>
                <el-table-column prop="user_station" label="岗位"></el-table-column>
                <el-table-column prop="store_name" label="所属门店"></el-table-column>
                <el-table-column prop="user_store" label="权限门店"></el-table-column>
                <el-table-column prop="user_area" label="服务区域"></el-table-column>
                <el-table-column prop="is_login" label="后台登录">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.is_login"
                                   @change="switchCheck(scope.row.is_login,scope.row.user_id,scope.row.name)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作"  width="300">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toSetMenuAuth(scope.row.user_id,scope.row.name)" v-if="scope.row.is_login">
                            设置权限菜单
                        </el-button>
                        <el-button type="text" @click="redirectPas(scope.row.user_id)">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 编辑弹窗 -->
        <el-dialog :title="titleInfo" :visible.sync="editDialog" width="680px" :before-close="beforeClose">
            <p>
                <span>员工：</span>
                <font color="#0081FF">{{ checkStaffNames }}</font>
            </p>
            <div class="station-group">
                <el-checkbox :indeterminate="false" @change="checkAllChange">全选</el-checkbox>
                <!-- 岗位管理 -->
                <el-checkbox-group v-model="checkedIdArray" @change="checkedStationChange">
                    <el-checkbox
                        v-for="item in editInfoList"
                        :key="item.id"
                        :label="item.id"
                    >{{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div slot="footer">
                <el-button @click="beforeClose" class="close-btn">取 消</el-button>
                <el-button type="primary" @click="setEdit(editInfo)">确 定</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import {
    getQwMember,
    menuList, openLoginSwitch, resetPassword, saveMenuAuth,
    saveStaffArea,
    saveStaffStation, saveStaffStore,
    selectAreaList,
    selectStationList,
    staffList,selectCompanyList
} from "../../../api/srv-store";
export default {
    name: "staffManage",
    data() {
        return {
            name: "",
            checkBrand: '',
            checkCompany: '',
            checkStation: "",
            checkArea: "",
            brandList: [],
            companyList: [],
            stationList: [],
            areaList: [],
            tableData: [],
            checkStaffIds: '',
            checkStaffNames: '',
            editDialog: false, //展示编辑弹窗
            editInfoList: [],//编辑弹窗展示信息
            checkedIdArray: [],//编辑弹窗选中数据
            checkedIds: '',//编辑弹窗选中数据
            editInfo: 0, //1岗位管理，2所属门店，3服务区域，4权限管理
            brandId:null,
            account: localStorage.getItem('account')
        };
    },
    computed: {
        titleInfo: function () {
            let val = '';
            switch (this.editInfo) {
                case 1:
                    val = '设置岗位';
                    break;
                case 2:
                    val = '设置权限门店';
                    break;
                case 3:
                    val = '设置服务区域';
                    break;
                case 4:
                    val = '设置权限菜单';
                    break;
                default:
                    val = '空'
            }
            return val;
        }
      
    },
    created() {
        this.checkBrand = JSON.parse(localStorage.getItem('brandId'))
        this.checkCompany = JSON.parse(localStorage.getItem('storesId'))
        if(this.checkBrand != null){
            this.getStationList()
            this.getCompanyList();
        }
    },
    methods: {
        getCompanyList: function(){
            selectCompanyList({
                brand_id: this.checkBrand
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.companyList = Er.data;
                }
            });
        },
        //获取岗位列表
        getStationList() {
            selectStationList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany
            }).then(Er => {
                if (Er.return_code === 200) {
                    let arr = Er.data;
                    arr.unshift({
                        id: '',
                        name: "请选择"
                    })
                    this.stationList = arr
                    this.getAreaList()//获取服务区域列表
                    this.getStaffList()
                }
            })
        },

        //获取服务区域列表
        getAreaList() {
            selectAreaList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany
            }).then(Er => {
                if (Er.return_code === 200) {
                    let arrs = Er.data
                    arrs.unshift({
                        id: '',
                        name: "请选择"
                    })
                    this.areaList = arrs;
                }
            })
        },

        //获取员工列表
        getStaffList() {
            staffList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany,
                station_id: this.checkStation,
                area_id: this.checkArea,
                name: this.name
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.tableData = Er.data
                }
            })
        },

        //员工全选事件
        selectionChange: function (val) {
            let idArr = [];
            let nameArr = [];
            val.map(item => {
                idArr.push(item.user_id)
                nameArr.push(item.name)
            })
            this.checkStaffIds = idArr.join(',')
            this.checkStaffNames = nameArr.join('、')
        },

        //关闭设置弹窗
        beforeClose: function () {
            this.editInfo = 0;
            this.editInfoList = [];
            this.checkedIdArray = [];
            this.checkedIds = '';
            this.editDialog = false;
        },

        //设置弹窗展示信息
        showEdit: function (val) {
            this.editInfo = val;
            if (this.checkStaffIds === '') {
                this.$message({
                    type: 'error',
                    message: '请选择员工!'
                });
                return false
            }
            if (val === 1) {//设置岗位
                let arr = JSON.parse(JSON.stringify(this.stationList));
                arr.shift();
                this.editInfoList = arr
                this.editDialog = true;
            } else if (val === 2) {//设置权限门店
                let newArr = []
                this.companyList.map(item => {
                    let obj = {}
                    obj.id = item.id
                    obj.name = item.alias_store_name
                    newArr.push(obj)
                })
                this.editInfoList = newArr
                this.editDialog = true;
            } else if (val === 3) {
                let arr = JSON.parse(JSON.stringify(this.areaList));
                arr.shift();
                this.editInfoList = arr
                this.editDialog = true; //设置服务区域
            }
        },

        //设置权限菜单
        toSetMenuAuth: function(user_id,user_name) {
            let _this = this;
            menuList({
                select_user_id: user_id,
            }).then(Er => {
                if (Er.return_code === 200) {
                    _this.editInfo = 4;
                    _this.editInfoList = Er.data;
                    Er.data.map(item => {
                        if (item.user_auth != null) {
                            _this.checkedIdArray.push(item.id)
                        }
                    })
                    _this.checkedIds = _this.checkedIdArray.join(',');
                    _this.checkStaffIds = user_id;
                    _this.checkStaffNames = user_name;
                    _this.editDialog = true;
                }
            }).catch(err => {
                console.log(err)
            })
        },

        //设置弹窗确认按钮
        setEdit: function (val) {
            if (val === 1) {
                saveStaffStation({
                    brand_id: this.checkBrand,
                    store_id: this.checkCompany,
                    user_id: this.checkStaffIds,
                    operation_station: this.checkedIds
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.editInfo = 0;
                        this.editInfoList = [];
                        this.checkedIdArray = [];
                        this.checkedIds = '';
                        this.editDialog = false;
                        this.getStaffList()
                    }
                })
            } else if (val === 2) {
                saveStaffStore({
                    brand_id: this.checkBrand,
                    user_id: this.checkStaffIds,
                    operation_store: this.checkedIds
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.editInfo = 0;
                        this.editInfoList = [];
                        this.checkedIdArray = [];
                        this.checkedIds = '';
                        this.editDialog = false;
                        this.getStaffList()
                    }
                })
            } else if (val === 3) {
                saveStaffArea({
                    brand_id: this.checkBrand,
                    store_id: this.checkCompany,
                    user_id: this.checkStaffIds,
                    operation_area: this.checkedIds
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.editInfo = 0;
                        this.editInfoList = [];
                        this.checkedIdArray = [];
                        this.checkedIds = '';
                        this.editDialog = false;
                        this.getStaffList()
                    }
                })
            } else if (val === 4) {
                saveMenuAuth({
                    user_id: this.checkStaffIds,
                    store_id: this.checkCompany,
                    brand_id: this.checkBrand,
                    operation_auth: this.checkedIds
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.editInfo = 0;
                        this.editInfoList = [];
                        this.checkedIdArray = [];
                        this.checkedIds = '';
                        this.editDialog = false;
                        this.getStaffList()
                    }
                })
            }
        },

        //设置弹窗全选
        checkAllChange: function (val) {
            if (val) {
                let newArr = []
                this.editInfoList.map(item => {
                    newArr.push(item.id)
                })
                this.checkedIdArray = newArr
                this.checkedIds = newArr.join(',')
            } else {
                this.checkedIdArray = []
                this.checkedIds = ''
            }

        },
        //设置弹窗单选
        checkedStationChange: function (val) {
            this.checkedIdArray = val
            this.checkedIds = val.join(',')
        },

        //重置密码
        redirectPas: function (user_id) {
            this.$confirm('是否重置密码为123456', '重置密码', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resetPassword({
                    user_id: user_id,
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: '重置成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },

        //设置登录权限
        switchCheck: function (val, user_id,user_name) {
            openLoginSwitch({
                brand_id: this.checkBrand,
                select_user_id: user_id,
                type: val ? 1 : 0
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.$message({
                        type: 'success',
                        message: Er.return_msg,
                    });
                    if (val) {//如果是打开的获取用户菜单权限
                        menuList({
                            select_user_id: user_id,
                        }).then(Er => {
                            if (Er.return_code === 200) {
                                this.editInfo = 4;
                                this.editInfoList = Er.data;
                                Er.data.map(item => {
                                    if (item.user_auth != null) {
                                        this.checkedIdArray.push(item.id)
                                    }
                                })
                                this.checkedIds = this.checkedIdArray.join(',');
                                this.checkStaffIds = user_id;
                                this.checkStaffNames = user_name;
                                this.editDialog = true;
                            }
                        })
                    } else {
                        this.editDialog = false;
                    }
                }
            })
        },

        //同步企微通讯录
        syncUser() {
            const loading = this.$loading({
                lock: true,
                text: '同步中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            getQwMember().then(Er => {
                loading.close();
                if (Er.return_code === 200) {
                    this.$message({
                        type: 'success',
                        message: Er.return_msg,
                    });
                    this.getStaffList();
                }
            }).catch(() => {
                loading.close();
            })
        }      
    }
};
</script>

<style scoped>
.staffManage {
    min-height: 100%;
    background-color: #f7fafb;
}

.title-page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
}

.staff-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-label {
    margin-left: 40px;
    margin-right: 20px;
    white-space: nowrap;
}
.first-label{
    margin-left: 0;
}

.staff-info {
    padding: 40px 0 20px 40px;
}

.content-staff {
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
}

.el-table {
    padding: 0 40px;
}

.staffManage /deep/ .el-dialog__footer {
    text-align: center;
}

.station-group {
    margin-top: 20px;
    padding: 20px;
    min-height: 200px;
    border: 1px solid rgba(62, 86, 110, 0.2);
    border-radius: 6px;
}

.station-group > .el-checkbox-group {
    margin-top: 20px;
}

.staffManage /deep/ .el-dialog__title {
    font-size: 20px;
    color: #3E566E;
    font-weight: 500;
}

.close-btn {
    background-color: rgba(62, 86, 110, 0.1);
    border-color: rgba(62, 86, 110, 0.1);
}
.btn-div{
    margin: 0 0 20px 40px;
}
</style>
<style>
.el-message--success {
    z-index: 9999 !important;
}
</style>